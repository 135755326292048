import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Typography,
  Skeleton,
  Stack,
  Card,
  Fab,
  Box,
  CardContent,
  Avatar
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { fetchData } from '../../api/Constants';

function Index() {
  const [posts, setPosts] = useState([1,2,3,4,5,6]);
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  
  useEffect(() => {
    fetchPosts()
  }, []);

  const handleChange = (event, value) => {
    setPage(value);
    fetchPosts(value)
  };

  const fetchPosts = async (page = 1) => {
    const data = await fetchData('/api/v1/posts', `page=${page}`);    
    setPosts(data.posts);
    setTotalPages(data.total_pages)
  }

  return (
    <div>
        <Typography component="h4" variant="h6" color="text.primary" sx={{mb: 1}}>Publicações</Typography>
          
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {posts.map((post, index) => {
              return (
                <Card elevation={4} key={index} sx={{mb: 2}}>
                  <CardContent>
                    {post.department ?
                      <Typography variant="overline" color="text.secondary">
                          <strong>{post.department} - {post.datetime}</strong>
                      </Typography> : <Skeleton variant="text" sx={{ fontSize: '1rem' }}/>}
                    {post.title ? 
                      <Typography component="h4" variant="inherit">
                        {post.title}
                      </Typography> : <Skeleton sx={{mb: 1}} variant="rectangular" height={50} />}
                    <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                      {post.attachment ?
                        <Fab color="secondary" href={post.attachment} aria-label="add" size="small">
                          <DownloadIcon />
                        </Fab> : 
                        <Avatar
                          alt="loading..."
                          src="/loading.gif"
                          sx={{ width: 40 }}
                        />}
                    </Box>
                  </CardContent>
                </Card>
              );
            })}
        </List>
          
        {/* Paginate */}
        {posts[0].title ? <Stack direction="row" justifyContent="center" sx={{ mt: 3 }}>
          <Pagination count={totalPages} page={page} onChange={handleChange} color="primary" />
        </Stack> : null}
       
    </div>
  );
}

export default Index;
