import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { ListItemButton, Typography, Breadcrumbs, Link, Skeleton, Chip, Stack } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LastPosts from '../posts/LastPosts';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Pagination from '@mui/material/Pagination';
import { fetchData } from '../../api/Constants'

function Index() {
    const [news, setNews] = useState(null);
    const [page, setPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);
    
    useEffect(() => {
        fetchNews()
    }, []);

    const fetchNews = async (page = 1) => {
        const data = await fetchData(`/api/v1/news`, `page=${page}`)
        setNews(data.news);
        setTotalPages(data.total_pages);
    }

    const handleChange = (event, value) => {
        setPage(value);
        fetchNews(value)
    };


    return (
        <div>
            <Grid container spacing={2} sx={{ mb: 2}}>
                <Grid item sm={8} xs={12}>
                    <Breadcrumbs aria-label="breadcrumb"  sx={{ mb: 2}}>
                        <Link underline="hover" color="inherit" href="/" sx={{ display: 'flex', alignItems: 'center' }}>
                            <HomeIcon sx={{ mr: 0.5 }} />
                            Página inicial
                        </Link>
                        <Typography color="text.primary">Notícias</Typography>
                    </Breadcrumbs>
                    <List sx={{ ml: -2, width: '100%', bgcolor: 'background.paper' }}>
                        {news ? news.map((news, index) => {
                            return(
                                <div key={index}>
                                    <ListItemButton component="a" href={`/news/${news.id}`} dense={true}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                { news.has_video ? (
                                                    <Avatar sx={{ bgcolor: '#5684BF' }}>
                                                        <YouTubeIcon />
                                                    </Avatar>) : (
                                                        <Avatar alt="DTI" src={news.cover} />
                                                    )
                                                }
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <React.Fragment>
                                                         <Typography
                                                            component="div"
                                                            variant="p"
                                                            color="text.primary"
                                                        >
                                                            {news.title}
                                                            { news.visualizations ? <Chip 
                                                                icon={<VisibilityIcon />} 
                                                                size="small" 
                                                                label={news.visualizations} 
                                                                variant="outlined" 
                                                                sx={{ float: 'right', border: 0}} /> : null }
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                                secondary={
                                                    <React.Fragment>
                                                        {news.department} - {news.datetime}
                                                    </React.Fragment>
                                                }
                                            />  
                                        </ListItem>
                                    </ ListItemButton>
                                    <Divider variant="inset" component="li" />
                                </div>
                            )       
                        }) : <Skeleton variant="rectangular" height={50} /> } 
                    </List>
                    {/* Paginate */}
                    {news ? <Stack direction="row" justifyContent="center" sx={{ mt: 3 }}>
                        <Pagination count={totalPages} page={page} onChange={handleChange} color="primary" />
                    </Stack> : null}   
                </Grid>
                <Grid item sm={4} xs={12} sx={{ display: { xs: 'none', md: 'block'}}}>
                    <LastPosts  />
                </Grid>
            </Grid>
        </div>
  );
}

export default Index;

