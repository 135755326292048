import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="#">
        DTI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function Login({ setToken }) {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpenSnackbar(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setOpenBackdrop(true)
        const fields = new FormData(event.currentTarget);
        const email = encodeURIComponent(fields.get('email'))
        const password = encodeURIComponent(fields.get('password'))
        
        const url = `${process.env.REACT_APP_OAUTH_HOST}/oauth/token`
        const type = process.env.REACT_APP_OAUTH_GRANT_TYPE
        const uid = process.env.REACT_APP_OAUTH_CLIENT_ID
        const secret = process.env.REACT_APP_OAUTH_CLIENT_SECRET
        const params = `grant_type=${type}&email=${email}&password=${password}&client_id=${uid}&client_secret=${secret}`
        const response = await fetch(`${url}?${params}`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'}
        })
        
        setOpenBackdrop(false)
        if(response.ok) {
          const data = await response.json();
          setToken(data.access_token)
          localStorage.setItem('uid', data.uid);
          localStorage.setItem('name', data.name);
          localStorage.setItem('avatar', data.avatar);
        } else {
          setOpenSnackbar(true)
        }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar 
            sx={{ m: 1, width: 96, height: 96 }} 
            alt="DTI" 
            src="/logo_378x361.png" />
          <Typography component="h1" variant="h5">
            Intranet
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Entrar
            </Button>
            <Link 
              href={`${process.env.REACT_APP_OAUTH_HOST}/users/password/new`} 
              underline="hover" 
              target="_blank" 
              rel="noopener" 
              sx={{ mt: 4, mb: 2 }}>
              Esqueci minha senha
            </Link>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Erro ao logar no sistema! Verifique suas credenciais."
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
      >
          <CircularProgress color="inherit" />
      </Backdrop>
    </ThemeProvider>
  );
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}