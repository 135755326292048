import React from 'react';
import { Button, Box } from '@mui/material'
import { Typography, Breadcrumbs, Link } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';
import CurrentContent from './CurrentContent';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

function Current({Components, setComponent}) {
    const handleClick = () => {
        setComponent(Components.archive)
    }

    return (
        <>   
            <Breadcrumbs aria-label="breadcrumb"  sx={{ mb: 2}}>
                <Link underline="hover" color="inherit" href="/" sx={{ display: 'flex', alignItems: 'center' }}>
                    <HomeIcon sx={{ mr: 0.5 }} />
                    Página inicial
                </Link>
                <Typography color="text.primary">
                    Boletim Interno
                </Typography>
            </Breadcrumbs>
            <Box display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
                sx={{ mb: 2}}>
                <Button 
                    variant="contained"
                    startIcon={<FormatListBulletedIcon />}
                    onClick={handleClick}>Boletins</Button>
            </Box>
            
           <CurrentContent uri={`/api/v1/reports`} />
        </>
    )
}

export default Current;