import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import {
  ListItemButton,
  Typography,
  Breadcrumbs,
  Link,
  Skeleton,
  Stack
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import LastNews from '../news/LastNews';
import Pagination from '@mui/material/Pagination';
import { fetchData } from '../../api/Constants';

function Index() {
  const [posts, setPosts] = useState(null);
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  
  useEffect(() => {
    fetchPosts()
  }, []);

  const handleChange = (event, value) => {
    setPage(value);
    fetchPosts(value)
  };

  const fetchPosts = async (page = 1) => {
    const data = await fetchData('/api/v1/posts', `page=${page}`);    
    setPosts(data.posts);
    setTotalPages(data.total_pages)
  }

  return (
    <div>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item sm={8} xs={12}>
          <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
            <Link
              underline="hover"
              color="inherit"
              href="/"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <HomeIcon sx={{ mr: 0.5 }} />
              Página inicial
            </Link>
            <Typography color="text.primary">Publicações</Typography>
          </Breadcrumbs>
          <List sx={{ ml: -2, width: '100%', bgcolor: 'background.paper' }}>
            {posts ? (
              posts.map((post, index) => {
                return (
                  <div key={index}>
                    <ListItemButton
                      component="a"
                      href={post.attachment}
                      dense={true}
                    >
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar alt="DTI" src="/logo.png" />
                        </ListItemAvatar>
                        <ListItemText
                          primary={post.title}
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              ></Typography>
                              {post ? (
                                `${post.department} - ${post.datetime}`
                              ) : (
                                <Skeleton variant="text" />
                              )}
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    </ListItemButton>
                    <Divider variant="inset" component="li" />
                  </div>
                );
              })
            ) : (
              <Skeleton variant="rectangular" height={50} />
            )}
          </List>
          
          {/* Paginate */}
          {posts ? <Stack direction="row" justifyContent="center" sx={{ mt: 3 }}>
            <Pagination count={totalPages} page={page} onChange={handleChange} color="primary" />
          </Stack> : null}
        </Grid>
        <Grid item sm={4} xs={12} sx={{ display: { xs: 'none', md: 'block'}}}>
          <LastNews />
        </Grid>
      </Grid>
    </div>
  );
}

export default Index;
