import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import Divider from '@mui/material/Divider'
import UndoIcon from '@mui/icons-material/Undo';
import { Typography, Breadcrumbs, Link } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';

import { fetchDataGet } from '../../api/Constants'

export default function Reports({Components, setComponent, dateSelected}) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchReport = async () => {
        const data = await fetchDataGet(`/api/v1/reports/${dateSelected}/index_to_read`)
        if (data) {
          setItems(data);
        }
    }
    fetchReport() 
  }, [dateSelected]);

  const handleClick = () => {
    setComponent(Components.archive)
  }

  const itemHandleClick = (event, id) => {
    window.location.replace(`/bi/${id}`);
  }

  return (
    <div style={{marginBottom: 10}}>
        <Breadcrumbs aria-label="breadcrumb"  sx={{ mb: 2}}>
                <Link underline="hover" color="inherit" href="/" sx={{ display: 'flex', alignItems: 'center' }}>
                    <HomeIcon sx={{ mr: 0.5 }} />
                    Página inicial
                </Link>
                <Typography color="text.primary">
                    Boletins
                </Typography>
        </Breadcrumbs>
        <Box display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
            sx={{ mb: 2}}>
            <Button variant="contained"
                    startIcon={<UndoIcon />}
                    onClick={handleClick}>Voltar</Button>
        </Box>
        <Card sx={{p: { xs: 0, sm: 2, md: 3}}}>
            <CardContent>
                <List>
                    {items.map((item, index) => {
                        return (
                                <span key={index}>
                                    <ListItem>
                                        <ListItemButton onClick={(e) => itemHandleClick(e, item.id)} role={undefined}>
                                            <StickyNote2Icon color="info" sx={{ mr: 2}} />
                                            <ListItemText
                                            primary={`Boletim nº ${item.sequence_number} de ${String(item.publication_date.unabbreviated)}`}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider component="li" />
                                </span>
                        )
                    })}
                </List>
            </CardContent>  
        </Card>
    </div>
  );
}