import { useEffect } from 'react';

function Logout() {
  useEffect(() => {  
    localStorage.clear();
    window.location.href = '/';
  }, []);

  return null;
}

export default Logout;
