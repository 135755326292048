import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import HomeIcon from '@mui/icons-material/Home'
import {
    Typography, ListItemButton, Breadcrumbs, Link, 
    Alert, Chip, Stack
} from '@mui/material';

import { fetchWorkScheduleData } from '../../api/Constants';

export default function WorkList() {
    const [works, setWorks] = useState(null)

    useEffect(() => {  
        fetchWorks()
    }, []);
    
    const fetchWorks = async () => {
        const patch = `/api/works`;
        const data = await fetchWorkScheduleData(patch)
        console.log(data)
        if (data && data.length > 0) setWorks(data)
    }
    
    return (
        <>
            <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
                <Link
                underline="hover"
                color="inherit"
                href="/"
                sx={{ display: 'flex', alignItems: 'center' }}
                >
                <HomeIcon sx={{ mr: 0.5 }} />
                    Página inicial
                </Link>
                <Typography color="text.primary">Escalas</Typography>
            </Breadcrumbs>

            {works ? 
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {works.map((work, index) => {
                    return(
                        <span key={index}>  
                            <ListItemButton component="a" href={`/works/${work.id}`}>
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar alt="DTI" src="/logo.png" />
                                    </ListItemAvatar>
                                    <ListItemText
                                    primary={
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            spacing={2}
                                            >
                                            {work.title}
                                             <Chip label={work.period} 
                                                color="primary" size="small" />
                                        </Stack>}
                                    secondary={
                                        <React.Fragment>
                                          <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                          >
                                            {work.job_type_name}
                                          </Typography>
                                          {` — ${work.description}`}
                                        </React.Fragment>
                                      }/>
                                </ListItem>
                                </ListItemButton>
                            <Divider variant="inset" component="li" />
                        </span>
                    )
                })}
            </List> : <Alert severity="warning">Nenhuma escala publicada foi encontrada!</Alert>}
            
        </>
  );
}