import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { 
    ListItemButton, Typography, Breadcrumbs, Link, Skeleton,
    Card, CardContent 
} from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';
import { fetchData } from '../api/Constants'
import { useParams } from "react-router-dom"

function Search() {
    const [items, setItems] = useState(null);
    const { term } = useParams();
    
    useEffect(() => { 
        const fetchSeach = async () => {
            const data = await fetchData('/api/v1/search', `term=${term}`)
            setItems(data);
        }
        fetchSeach()
    }, [term]);

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb"  sx={{ mb: 2}}>
                <Link underline="hover" color="inherit" href="/" sx={{ display: 'flex', alignItems: 'center' }}>
                    <HomeIcon sx={{ mr: 0.5 }} />
                    Página inicial
                </Link>
                <Typography color="text.primary">Pesquisa</Typography>
            </Breadcrumbs>
            <Card sx={{ minWidth: 275,
                backgroundImage: `url(${"./logo.png"})`,
                backgroundSize: '55%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center' }}>
            <CardContent sx={{ bgcolor: 'background.paper', opacity: 0.9 }}>
                <Typography gutterBottom variant="h5" component="span">
                    Exibindo resultados para: <b>{term}</b>
                </Typography>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {items ? items.map((item, index) => {
                        return(
                            <div key={index}>
                                <ListItemButton component="a" href={item.url} dense={true}>
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            <ManageSearchIcon />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <React.Fragment>
                                                        <Typography
                                                        component="p"
                                                        variant="p"
                                                        color="text.primary"
                                                    >
                                                        {item.title}
                                                    </Typography>
                                                </React.Fragment>
                                            }
                                            secondary={
                                                <React.Fragment>
                                                    {item.type}: {item.datetime}
                                                </React.Fragment>
                                            }
                                        />  
                                    </ListItem>
                                </ ListItemButton>
                                <Divider variant="inset" component="li" />
                            </div>
                        )       
                    }) : <Skeleton variant="rectangular" height={50} /> } 
                </List>
            </CardContent>
        </Card>
    </div>
  );
}

export default Search;

