import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import CalendarTodayIcon from '@mui/icons-material/Today';
import {Divider, Badge} from '@mui/material'
import UndoIcon from '@mui/icons-material/Undo';
import { Typography, Breadcrumbs, Link } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';

import { fetchDataGet } from '../../api/Constants'

export default function Archive({Components, setComponent, setDateSelected}) {
  const [items, setItems] = useState([]);
  const [expanded, setExpanded] = React.useState('panel-0');

  useEffect(() => {
    fetchReport() 
  }, []);

  const fetchReport = async () => {
    const data = await fetchDataGet(`/api/v1/filters`)
    if (data) {
      const entries = Object.entries(data);
      setItems(entries.reverse());
    }
  }

  const accordionHandleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const buttonHandleClick = () => {
    setComponent(Components.current)
  }

  const itemHandleClick = (event, date) => {
    setDateSelected(date)
    setComponent(Components.reports)
  }

  return (
        <div>
          <Breadcrumbs aria-label="breadcrumb"  sx={{ mb: 2}}>
                <Link underline="hover" color="inherit" href="/" sx={{ display: 'flex', alignItems: 'center' }}>
                    <HomeIcon sx={{ mr: 0.5 }} />
                    Página inicial
                </Link>
                <Typography color="text.primary">
                    Boletins
                </Typography>
          </Breadcrumbs>

          <Box display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
            sx={{ mb: 2}}>
            <Button 
              variant="contained"
              startIcon={<UndoIcon />}
              onClick={buttonHandleClick}>Voltar</Button>
          </Box>
          
          
          {items.map((item, index) => {
            return (
              <Accordion 
                key={index}
                expanded={expanded === `panel-${index}`} 
                onChange={accordionHandleChange(`panel-${index}`)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography>
                    <b>{item[0]}</b>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    {item[1].map((h, index) => {
                      return (
                        <span key={index}>
                          <Divider component="li" />
                          <ListItem>
                            <ListItemButton onClick={(e) => itemHandleClick(e, h.date)}>
                              <Badge badgeContent={h.amount} color="info" sx={{ mr: 3}}>
                                <CalendarTodayIcon color="action" />
                              </Badge>
                              
                              <ListItemText
                                primary={`Boletins de ${h.dateToShow}`}
                              />
                            </ListItemButton>
                          </ListItem>
                        </span>
                      )
                    })}
                  </List>
                </AccordionDetails>
              </Accordion>
            )
          })}
        </div>
  );
}