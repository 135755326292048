import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Divider, Breadcrumbs, Link, Chip, Stack } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home'

import { fetchWorkScheduleData } from '../../api/Constants';
import RankMembers from '../../sections/works/rank/members';


export default function WorkShow() {
    const [members, setMembers] = useState([])
    
    useEffect(() => {  
        fetchWorks()
    }, []);
    
    const fetchWorks = async () => {
        const patch = `/api/rank`;
        const data = await fetchWorkScheduleData(patch)

        if (data) {
            setMembers(data)
            console.log(data)
        }
    }

  return (
    <>
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
            <Link underline="hover"
                color="inherit"
                href="/"
                sx={{ display: 'flex', alignItems: 'center' }}>
                <HomeIcon sx={{ mr: 0.5 }} />
                    Página inicial
            </Link>
            <Link underline="hover"
                color="inherit"
                href="/works"
                sx={{ display: 'flex', alignItems: 'center' }}>
                    Escalas
            </Link>
            <Typography color="text.primary">Relatório</Typography>
        </Breadcrumbs>
        <Card elevation={3} sx={{mb: 4}}>
            <CardContent>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    >
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {""}
                    </Typography>
                    <Chip label={"CHIP"} color="primary" size="small" />
                </Stack>
                
                <Typography variant="h5" component="div">
                    {""}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {""}
                </Typography>
                <Divider />
                <RankMembers members={members} />
            </CardContent>
        </Card>
    </>
  );
}
