import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from '../components/home/Index';
import Posts from '../components/posts/Index';
import ListNews from '../components/news/Index';
import News from '../components/news/News';
import Evaluations from '../components/evaluations/Index';
import Groups from '../components/evaluations/Groups';
import Users from '../components/evaluations/Users';
import Logout from '../components/Logout';
import Search from '../components/Search';
import Departments from '../components/departments/Index';
import PostDepartments from '../components/departments/posts';
import Reports from '../components/reports/Index';
import Contacts from '../components/contacts/Index';
import Bi from '../components/bi/Index';
import BiSelected from '../components/bi/Selected';
import WorkList from '../pages/works/index';
import WorkShow from '../pages/works/show';
import WorkReport from '../pages/works/reports';

function DefaultRoutes() {
  return (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="home" element={<Home />} />
            <Route path="search/:term" element={<Search />} />
            <Route path="posts" element={<Posts />} />
            <Route path="news" element={<ListNews />} />
            <Route path="news/:newsId" element={<News />} />
            <Route path="evaluations" element={<Evaluations />} />
            <Route path="evaluations/:evaluationId/groups" element={<Groups />} />
            <Route path="evaluations/:evaluationId/groups/:groupId/users" element={<Users />} />
            <Route path="departments" element={<Departments />} />
            <Route path="departments/posts" element={<PostDepartments />} />
            <Route path="reports" element={<Reports />} />
            <Route path="contacts" element={<Contacts />} />
            <Route path="bi" element={<Bi />} />
            <Route path="bi/:id" element={<BiSelected />} />
            <Route path="works/" element={<WorkList />} />
            <Route path="works/:id" element={<WorkShow />} />
            <Route path="works/reports" element={<WorkReport />} />
        </Routes>
    </BrowserRouter>
  );
}

export default DefaultRoutes;
