import React from 'react';
import { Button, Box } from '@mui/material'
import { Typography, Breadcrumbs, Link } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';
import { useParams } from "react-router-dom"
import CurrentContent from './CurrentContent';
import UndoIcon from '@mui/icons-material/Undo';

function Selected() {
    const { id } = useParams();

    const handleClick = () => {
        window.location.replace(`/bi`);
    }

    return (
        <>  
            <Breadcrumbs aria-label="breadcrumb"  sx={{ mb: 2}}>
                <Link underline="hover" color="inherit" href="/" sx={{ display: 'flex', alignItems: 'center' }}>
                    <HomeIcon sx={{ mr: 0.5 }} />
                    Página inicial
                </Link>
                <Typography color="text.primary">
                    Boletim Interno
                </Typography>
            </Breadcrumbs>
            <Box display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
                sx={{ mb: 2}}>
                <Button variant="contained"
                        startIcon={<UndoIcon />} 
                        onClick={handleClick}>Voltar para o boletim mais recente</Button>
            </Box>
            
           <CurrentContent uri={'/api/v1/reports'} params={`id=${id}`} />
        </>
    )
}

export default Selected;