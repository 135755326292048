import React, { useState } from 'react';
import Current from './Current';
import Archive from './Archive';
import Reports from './Reports';

const Components = {
    current: 'current',
    archive: 'archive',
    reports: 'reports'    
}

function Index() {
    const [component, setComponent] = useState(Components.current);
    const [dateSelected, setDateSelected] = useState(Components.current);

    return (
        <>
            {component === Components.current ? <Current setComponent={setComponent} Components={Components} /> : null}
            {component === Components.archive ? <Archive setComponent={setComponent} setDateSelected={setDateSelected} Components={Components} /> : null}
            {component === Components.reports ? <Reports setComponent={setComponent} dateSelected={dateSelected} Components={Components} /> : null}            
        </>
    );
}

export default Index;

