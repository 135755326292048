import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import CustomSkeleton from './CustomSkeleton'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { fetchDataGet } from '../../api/Constants'
import GenericPdfDownloader from './GenericPdfDownloader';

const BI_CURRENT_CONTENT_ID = "bi-current-content"; 

function Current({uri, params}) {
    const [report, setReport] = useState([1,2,3,4]);
    const [snippets, setSnippets] = useState([]);
    const [retry, setRetry] = useState(0);

    useEffect(() => {
        fetchReport() 
    }, []);

    useEffect(() => {
        if (retry > 0 && retry <= 3) {
            fetchReport()
            console.log('retrying...');
        }
    }, [retry]);

    const topics = (arr) => {
        return arr.filter(element => {
            return element !== null;
        });
    }

    const fetchReport = async () => {
        const data = await fetchDataGet(uri, params)
        if (data) {
            setReport(data);
            setSnippets(data.snippets);
            
        } else {
            setRetry(retry+1)
        }
    }

    const format_content = (content) => {
        content = content.replace('align="center"', 'width=100% overflow-x=auto')
        content = content.replace('cellpadding="1"', 'cellpadding=10')
        content = content.replace('cellpadding="0"', 'cellpadding=10')
        content = content.replace('cellspacing="1"', 'cellspacing=0')
        return content
    }

    return (
        <>  
            {report && report.sequence_number ?
                <Box display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    sx={{ mb: 2}}>
                    <GenericPdfDownloader 
                        downloadFileName={`CORGER_BI_${report.sequence_number}_DE_${report.date.original}`} 
                        rootElementId={BI_CURRENT_CONTENT_ID} 
                    />
                </Box> : null }
            

            <Card sx={{p: { xs: 0, sm: 2, md: 3}}}>
                <CardContent id={BI_CURRENT_CONTENT_ID} sx={{ p: 5}}>
                    {report && report.sequence_number ? <Typography variant="h5" sx={{fontFamily: 'Monospace', fontWeight: 'bold'}}>
                                Boletim interno nº {report.sequence_number} de {report.date.unabbreviated}
                                </Typography> : <CustomSkeleton size={7} /> }
                    
                    {snippets.map((item, index) => {
                        return (
                            <div key={index}>
                                <Typography variant="h4" component="h2" sx={{ mt: 3, mb: 1 }}>
                                    {item ? item.snippet ? item.snippet.title : null : null}
                                </Typography>
                                {item ? item.topics ? topics(item.topics).map((topic, index) => {
                                    return (
                                        <div key={index} style={{ display: 'block' }}>
                                            <Typography color="gray" 
                                                variant="h5"
                                                sx={{  mb: 3, fontWeight: 'bold' }}>
                                                {topic.title}
                                            </Typography>
                                            {topic.notes.map((note, index) => {
                                                return(
                                                    <div key={index} style={{ display: 'block' }}>
                                                        <Typography sx={{ fontWeight: 'bold' }}>
                                                            {note.title}
                                                        </Typography>
                                                        <Box sx={{ overflow: 'auto' }}
                                                            dangerouslySetInnerHTML={{__html: format_content(note.content) }} />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                }) : 'Sem alteração' : null} 
                            </div>
                        )
                    })}
                </CardContent>  
            </Card>
        </>
    )
}

export default Current;