import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import DefaultRoutes from './routes/default';
import MobileRoutes from './routes/mobile';
import Menu from '../src/components/Menu'
import MenuBottom from '../src/components/MenuBottom'
import Footer from '../src/components/Footer'
import Login from '../src/components/Login';
import useToken from '../src/components/useToken';


const theme = createTheme({
  palette: {
    primary: {
      main: '#5684BF',
      contrastText: "#fff"
    },
    secondary: {
      main: '#ec9f03', //'#ffcb00ff'
      contrastText: "#fff"
    },
  },
});

function App() {
  const { setToken, getToken } = useToken();
 
  if(!getToken()) {
    return <Login setToken={setToken} />
  }

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
  }
  
  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
  }
  
  return (
    <ThemeProvider theme={theme}>
      <Default>
        <Menu />
        <Container maxWidth='xl' sx={{ mt: 3 }}>
          <DefaultRoutes />
        </Container>
        <Footer />
      </Default>
      
      <Mobile>
        <Menu />
        <Container sx={{mb: 10}}>
          <MobileRoutes />
        </Container>
        <MenuBottom />
      </Mobile>

    </ThemeProvider>
  );
 
}

export default App;
