import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { ListItemButton, Typography, Breadcrumbs, Link } from '@mui/material'
import { Card, CardContent, Skeleton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Alert from '@mui/material/Alert';
import UserScore from './UserScore';
import { fetchData } from '../../api/Constants'


function Index() {
    const [evaluations, setEvaluations] = useState(null);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => { 
        const uid = localStorage.getItem('uid')
        const fetchEvaluations = async () => {
            const data = await fetchData(`/api/v1/users/${uid}/evaluations`);
            setEvaluations(data);
            if (data.length === 0) setShowAlert(true)
        }  
        fetchEvaluations()  
    }, []);

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb"  sx={{ mb: 2}}>
                <Link underline="hover" color="inherit" href="/" sx={{ display: 'flex', alignItems: 'center' }}>
                    <HomeIcon sx={{ mr: 0.5 }} />
                    Página inicial
                </Link>
                <Typography color="text.primary">Avaliação 360</Typography>
            </Breadcrumbs>

            <Card sx={{ 
                minHeight: 400, 
                marginBottom: 40,
                backgroundImage: `url(${"/logo.png"})`,
                backgroundSize: '20%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center' }}>
                <CardContent sx={{ bgcolor: 'background.paper', opacity: 0.92,  minHeight: 400 }}>
                    <UserScore  />

                    { showAlert ? <Alert severity="info" sx={{ mb: 5}}>Nehuma avaliação 360 foi encontrada.</Alert> : null }

                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {evaluations ? evaluations.map((data, index) => {
                            return(
                                <ListItemButton key={index} component="a" href={`/evaluations/${data.evaluation.id}/groups`} dense={true}>
                                    <ListItem alignItems="flex-start" selected={true} divider={true}>
                                        <ListItemAvatar>
                                            <Avatar sx={{ bgcolor: '#faaf00'}}> 
                                                <AssessmentIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={data.evaluation.name}
                                            secondary={data.evaluation.description}
                                        />  
                                    </ListItem>
                                </ ListItemButton>
                            )       
                        }) : <Skeleton variant="rectangular" height={50} />} 
                    </List>
                </CardContent>
            </Card>
        </div>
  );
}

export default Index;