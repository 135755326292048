import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import {
  Paper,
  Typography,
  Breadcrumbs,
  Link,
  Skeleton,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { fetchData } from '../../api/Constants';

function Index() {
  const [departments, setDepartments] = useState(null);
  
  useEffect(() => {
    const fetchDepartments = async () => {
      const data = await fetchData("/api/v1/departments");
      setDepartments(data)
    }
    fetchDepartments()
  }, []);

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link
          underline="hover"
          color="inherit"
          href="/"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <HomeIcon sx={{ mr: 0.5 }} />
          Página inicial
        </Link>
        <Typography color="text.primary">Departamentos</Typography>
      </Breadcrumbs>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        {departments ? (
          departments.map((department, index) => {
            return (
              <Grid item sm={3} xs={12} key={index}>
                <Paper sx={{ p: 2 }} >
                  <Link
                    underline="none"
                    color="inherit"
                    href={`/departments/posts?department=${department}`}
                    sx={{ display: 'flex', alignItems: 'center',  justifyContent:"center" }}
                  >
                    {department}
                  </Link> 
                </Paper>
              </Grid>     
            );
          })
        ) : (
          [...Array(12).keys()].map((n, index) => {
            return (
              <Grid item sm={3} xs={12} key={index}>
                <Skeleton variant="rectangular" height={50} />
              </Grid>     
            );
          })
          
        )}
      </Grid>
    </div>
  );
}

export default Index;
