import React, { useState } from 'react';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import LoadingButton from '@mui/lab/LoadingButton';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';


const GenericPdfDownloader = ({rootElementId , downloadFileName}) => {
    const [loading, setLoading] = useState(false);

    const downloadPdfDocument = () => {        
        setLoading(true);
        const input = document.getElementById(rootElementId);
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL("image/png", 1.0);
            const imgWidth = 210; 
            const pageHeight = 295;  
            const imgHeight = canvas.height * imgWidth / canvas.width;
            
            let heightLeft = imgHeight;
            let doc = new jsPDF('p', 'mm', 'a4');
            let position = 0;

            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }
            doc.save(`${downloadFileName}.pdf`);
            setLoading(false);
        })
    }

    return <LoadingButton
                color="error"
                onClick={downloadPdfDocument}
                loading={loading}
                loadingPosition="start"
                startIcon={<PictureAsPdfIcon />}
                variant="contained">
                <span>Baixar PDF</span>
            </LoadingButton>
}

export default GenericPdfDownloader;