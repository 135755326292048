import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Grid from '@mui/material/Grid';
import { Typography, Breadcrumbs, Link, Skeleton, Chip, Box } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LastNews from './LastNews';
import { fetchData } from '../../api/Constants'

function News() {
    const { newsId } = useParams();
    const [news, setNews] = useState(null);

    useEffect(() => {
        const setFetch = async () => {
            const data = await fetchData(`/api/v1/news/${newsId}`)
            setNews(data);
        }
        setFetch()
    }, [newsId]);

    return (
        <div>
            <Grid container spacing={2} sx={{ mb: 2}}>
                <Grid item sm={8} xs={12}>
                    <Breadcrumbs aria-label="breadcrumb"  sx={{ mb: 5}}>
                        <Link underline="hover" color="inherit" href="/" sx={{ display: 'flex', alignItems: 'center' }}>
                            <HomeIcon sx={{ mr: 0.5 }} />
                            Página inicial
                        </Link>
                        <Typography color="text.primary">Notícia</Typography>
                    </Breadcrumbs>
                    
                    <Grid container spacing={2}  direction="row" alignItems="center" justifyContent="center">
                        <Grid item sm={12} md={5}>
                            { news ? news.has_video ? (
                                <Box component="iframe" 
                                    src={news.video_url}
                                    frameBorder="0"
                                    sx={{ 
                                        display: "block",
                                        maxHeight: 400, 
                                        maxWidth:  { xs: 300, md: 220, lg: 300 },  
                                        marginLeft: "auto",  
                                        marginRight: "auto" 
                                    }} 
                                    allowFullScreen
                                    autoPlay={false}
                                    title="video" />
                                ) : (
                                <Box component="img" 
                                    src={news.attachment} 
                                    alt="attachment-logo"
                                    sx={{ 
                                        display: "block",
                                        maxHeight: 400, 
                                        maxWidth:  { xs: 300, md: 220, lg: 300 },  
                                        marginLeft: "auto",  
                                        marginRight: "auto" 
                                    }} 
                                />) : <Skeleton /> }
                        </Grid>
                        <Grid item sm={12} md={7}>
                            <Typography variant="h5" component="h2" >
                                { news ? news.title : <Skeleton variant="text" />}
                            </Typography>

                            <Typography variant="overline" component="div">
                                { news ? `${news.department} - ${news.datetime}` : <Skeleton variant="text" /> }
                    
                                { news ? <Chip 
                                    icon={<VisibilityIcon />} 
                                    size="small" 
                                    label={news.visualizations} 
                                    variant="outlined" 
                                    sx={{ float: 'right', border: 0}} /> : null }
                            </Typography>
                            <Typography variant="h6" component="h2" color="gray" sx={{ mb: 1}} >
                                {news ? news.subtitle : <Skeleton variant="text" />}
                            </Typography>
                        </Grid>
                    </Grid>
                    <div>
                        {  news ? 
                            <div dangerouslySetInnerHTML={{__html: news.content }} />
                            : <Skeleton variant="text" />
                        }        
                    </div>
                </Grid>
                <Grid item sm={4} xs={12}>
                    <LastNews  />
                </Grid>
            </Grid>
        </div>
  );
}

export default News;

